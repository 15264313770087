
.blog-page {
    padding: 3rem 2rem;
    text-align: center;
    background: #121212;
    color: #fff;
}

.blog-title {
    font-size: 3rem;
    font-weight: bold;
    color: #ffdd57;
    margin-bottom: 0.5rem;
}

.blog-subtitle {
    font-size: 1.5rem;
    font-weight: 300;
    color: #b0b0b0;
    margin-bottom: 2rem;
}

.blog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
}

.blog-card {
    background: #1c1c1c;
    border: 1px solid #2a2a2a;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
}

.blog-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    display: block;
}

.blog-content {
    padding: 1.5rem;
    flex-grow: 1;
}

.blog-heading {
    font-size: 1.5rem;
    margin: 0.5rem 0;
    color: #ffdd57;
}

.blog-author {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    color: #b0b0b0;
}

.blog-excerpt {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    color: #d0d0d0;
}

.read-more {
    background: #ffdd57;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    color: #121212;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s;
}

.read-more:hover {
    background: #ffdd57;
}

.view-more {
    background: #ffdd57;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    color: #121212;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s;
    margin-top: 2rem;
}

.view-more:hover {
    background: #ffdd57;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
    background-color: #1c1c1c;
    margin: 2rem;
    padding: 2rem;
    border: 1px solid #2a2a2a;
    border-radius: 8px;
    width: 80%;
    max-width: 640px;
    color: #fff;
    text-align: left;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
}

.close {
    color: #aaa;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}

.modal-image {
    width: 100%;
    aspect-ratio: 16/9;
    margin-bottom: 1rem;
    border-radius: 15px;
}