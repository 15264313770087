.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: #0d0d0d;
    color: #fff;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.navbar .logo img {
    height: 40px;
}

.navbar .nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
}

.navbar .nav-links li {
    display: inline;
}

.navbar .nav-links a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s;
}

.navbar .nav-links a:hover, .navbar .nav-links .active {
    color: #ffdd57;
}

.logo{
    z-index: 9;
}