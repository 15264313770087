body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #0d0d0d;
    color: #fff;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.App {
    text-align: center;
    height: clamp(100vh, 100vh, 100vh);
}

@media (max-width: 768px) {
    .navbar .nav-links {
        /* flex-direction: column; */
        gap: 10px;
    }
}