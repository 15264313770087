.footer {
    background: #0d0d0d;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    /* position: fixed; */
    width: 100%;
    bottom: 0;
    z-index: 1000;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
}