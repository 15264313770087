.not-found{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 120px; /* Adjust for navbar height */
    padding-bottom: 80px; /* Adjust for footer height */
    background: linear-gradient(135deg, #0d0d0d, #1a1a1a);
    color: #fff;
    text-align: center;
    height: 100vh;
}