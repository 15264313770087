.section_npm-packages{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 120px;
    padding-bottom: 80px;
    background: linear-gradient(135deg, #0d0d0d, #1a1a1a);
    color: #fff;
    text-align: center;
    min-height: 100vh;
}

.section_package-detail{
    display: flex;
    flex-direction: column;
    align-items: left;
    padding-top: 40px;
    padding-bottom: 80px;
    background: linear-gradient(135deg, #0d0d0d, #1a1a1a);
    color: #fff;
    text-align: left;
    min-height: 100vh;
    padding-left: 40px;


}
.readme-container {
    padding: 40px;
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
    color: #e0e0e0;
    background: linear-gradient(135deg, #1a1a1a, #333);
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
    max-width: 800px;
    margin: 40px auto;
    position: relative;
    overflow: hidden;
}

.readme-container::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    /* background: radial-gradient(circle, rgba(255, 221, 87, 0.2), transparent 70%); */
    transition: transform 0.5s;
    transform: scale(0);
    z-index: 0;
}

.readme-container:hover::before {
    transform: scale(1);
}

.readme-container h1, .readme-container h2, .readme-container h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #ffdd57;
    text-shadow: 0 0 10px rgba(255, 221, 87, 0.7);
}

.readme-container ul, .readme-container ol {
    padding-left: 20px;
    margin-bottom: 20px;
}

.readme-container li {
    margin-bottom: 10px;
}

.readme-container pre {
    background: #272822;
    padding: 20px;
    border-radius: 10px;
    overflow-x: auto;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}

.readme-container code {
    background: #272822;
    padding: 2px 5px;
    border-radius: 3px;
    color: #f8f8f2;
}

.readme-container a {
    color: #ffdd57;
    text-decoration: none;
    transition: color 0.3s;
}

.readme-container a:hover {
    color: #fff;
}

.readme-container img {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
}