 .projects {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 80px; 
    background: linear-gradient(135deg, #0d0d0d, #1a1a1a);
    color: #fff;
    text-align: center;
    min-height: 100vh;
}