.resume_body {
  * {
    text-align: left;
    line-height: 1.5;
    margin: 0;
    padding: 0;
  }

  font-family: Arial,
  sans-serif;
  line-height: 1.25;
  margin: 0;
  background-color: #f4f4f4;
  padding: 80px 20px 20px;

}

.resume {

  max-width: 794px;
  margin: 0 auto;
  padding: 0.5in;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  page-break-inside: avoid;
}

.resume_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
  gap: 20px;
  align-items: flex-start;
}

.resume_header--heading {
  margin: 0;
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 5px;
  color: #305072
}

.resume_header--contact-info>span {
  /* display: inline-block; */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  margin: 0;
  padding: 0;
  line-height: 1.8;
  color: #305072
}

.contact-info--label {
  display: inline-block;
  min-width: 60px;
}

.resume_header--contact-info a {
  text-decoration: none;
  text-align: right;
  color: #305072
}

.resume_header--links a {
  /* color: #0073b1; */
  text-decoration: none;
  text-align: center;
}

.resume_header--links a:hover,
.resume_header--contact-info a:hover {
  text-decoration: underline;
}

.resume_education,
.resume_skills,
.resume_experience,
.resume_projects,
.resume_open_source {
  margin-top: 10px;
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
}

.resume_certificates {
  margin-top: 10px;
  padding-bottom: 5px;
}

.resume h2 {
  font-size: 20px;
  margin-bottom: 10px;
  /* border-top: 2px solid #000; */
  /* padding-bottom: 5px; */
  text-align: center;
  font-weight: bold;
  color: #305072
}

.resume h3 {
  font-size: 16px;
  /* margin: 10px 0 5px; */
  font-weight: bold;
  color: #305072
}

.resume_education--item {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 10px; */
}

.resume_experience--item>div,
.resume_project--item>div,
.resume_certificate--item>div {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.resume ul {
  padding-left: 20px;
  margin-top: 5px;
}

.resume_education--item ul,
.resume_experience--item ul,
.resume_project--item ul,
.resume_certificate--item ul {
  /* margin: 5px 0; */
  padding-left: 40px;
}

.resume ul li {
  /* margin-bottom: 5px; */
  list-style-type: disc;
}

.resume_education--item ul li,
.resume_experience--item ul li,
.resume_project--item ul li {
  list-style-type: disc;

}

.resume strong {
  font-weight: bold;
  color: #305072;
  font-size: 12px
}

.resume-download {
  margin: 20px auto;
  position: fixed;
  bottom: 0;
  background-color: #000000b0;
  color: #fff;
  padding: 5px 18px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 12px;
}

.page-break {
  display: none;
}

.page-breake-space {
  display: none;
}

@media print {
  .navbar {
    display: none !important;
  }

  .footer {
    display: none;
  }

  .resume_body {
    padding: 0 !important;
  }

  header,
  footer {
    display: none;
  }

  .resume {
    margin: 0;
    box-shadow: unset;
    page-break-inside: avoid;
    padding: 1cm;
  }

  .resume-download {
    display: none;
  }

  .print-content {
    display: block;
    page-break-inside: avoid;
    page-break-before: auto;
    page-break-after: auto;
  }

  .page-break {
    display: block;
    page-break-before: always;
  }

  .page-breake-space {
    display: block;
    padding-top: 0.5in;
  }

  @page {
    margin: 0;
    size: auto;

    @top-left {
      content: none;
    }

    @top-center {
      content: none;
    }

    @top-right {
      content: none;
    }

    @bottom-left {
      content: none;
    }

    @bottom-center {
      content: none;
    }

    @bottom-right {
      content: none;
    }
  }
}