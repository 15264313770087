html, body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    color: #333;
    line-height: 1.6;
    /* width: 100VW;
    overflow-y: hidden;
    overflow-x: auto; */
}

* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.header {
    background: #007bff;
    color: #fff;
    padding: 20px 0;
    text-align: center;
}

.navbar {
    display: flex;
    justify-content: center;
    padding: 10px 0;
}

.navbar a {
    margin: 0 15px;
    font-weight: bold;
}

.section {
    padding: 60px 0;
}

.footer {
    text-align: center;
    padding: 20px 0;
    background: #333;
    color: #fff;
}

@media (max-width: 768px) {
    .navbar {
        /* flex-direction: column; */
    }

    .navbar a {
        /* margin: 10px 0; */
    }
}